.container-singleimage {
	padding: 0 !important;
}
.mco-singleimage-02 .mco-singleimage-02__image {
	align-items: flex-end;
}
.container-singleimage:not(.has-title) .mco-singleimage-02 .mco-singleimage-02__image:before {
	display: none;
}
.container-singleimage:not(.has-title) .mco-singleimage-02 .mco-singleimage-02__image {
	min-height: auto !important;
}
.container-singleimage:not(.has-title) .mco-singleimage-02 .mco-singleimage-02__image img {
	object-fit: contain !important;
	position: relative;
}
.container-singleimage:not(.has-title) .mco-singleimage-02 .mco-singleimage-02__content {
	min-height: auto !important;
}
.mco-singleimage-02 .mco-singleimage-02__content p {
	color: #ffffff !important;
}

@media all and (min-width: 600px) {
	.mco-singleimage-02 .mco-singleimage-02__image {
		align-items: center;
	}
}
@media all and (min-width: 960px) {
	.mco-singleimage-02 .mco-singleimage-02__content {
		margin-right: auto;
		max-width: clamp(300px, 50%, 650px);
		padding-left: 56px;
	}
}
@media all and (min-width: 1280px) {
	.mco-singleimage-02 .mco-singleimage-02__image {
		min-height: 730px;
	}
}
