/* Interaktionslayer */

.micado-ial-item-newsletter__form-button button,
.micado-ial-item-countdown__button,
.micado-ial-item-message__button,
.micado-ial-item-offer__button {
	background: var(--mco-palette-primary-main);
	border: 0;
	border-radius: 0;
	box-shadow: none !important;
	cursor: pointer;
	font-family: "Rum-Serif" !important;
	font-size: 16px;
	font-weight: 700;
	height: 48px;
	letter-spacing: 0.1em;
	line-height: 48px;
	min-width: 64px;
	padding: 0 16px;
	text-align: center;
	text-transform: unset;
	transition: all 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
	width: auto !important;
}
.micado-ial-item-newsletter__form-button:hover button,
.micado-ial-item-countdown__button:hover,
.micado-ial-item-message__button:hover,
.micado-ial-item-offer__button:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__notification .micado-ial__close:hover .micado-svg-icon svg path,
.micado-ial__message .micado-ial__close:hover .micado-svg-icon svg path {
	fill: #ffffff;
}
.micado-ial-item-notification__button--is-icon:hover {
	background: var(--mco-palette-primary-dark);
}
.micado-ial__close,
.micado-overlay__close {
	background: #ededed !important;
}
.micado-ial__close:hover,
.micado-overlay__close:hover {
	background: var(--mco-palette-primary-main) !important;
	color: #ffffff !important;
}
.micado-ial__close:hover svg path,
.micado-overlay__close:hover svg path {
	fill: #ffffff !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-countdown__headline,
.micado-ial-item-message__headline,
.micado-ial-item-newsletter__headline {
	color: var(--mco-palette-primary-main) !important;
	font-family: "Rum-Serif" !important;
	font-size: 32px !important;
}
.micado-ial-item-contact__headline,
.micado-ial-item-newsletter__headline {
	text-align: left !important;
}
.micado-ial-item-contact__text,
.micado-ial-item-newsletter__text {
	text-align: left !important;
}
.micado-ial-item-message__buttons {
	justify-content: flex-end !important;
}
