.container-facts {
	background: #f0856c;
	padding-bottom: 28px;
	padding-top: 44px;
}
.facts__headline {
	color: #ffffff !important;
}
.facts__item {
	color: #ffffff;
}
.facts__item .facts__item-title {
	margin-bottom: 8px;
}

@media all and (min-width: 1280px) {
	.container-facts {
		padding-bottom: 68px;
		padding-top: 104px;
	}
}
