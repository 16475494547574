.mco-teaser-list-01 .swiper-container {
	cursor: default !important;
	padding-bottom: 70px !important;
}
.mco-teaser-list-01 .swiper-pagination-bullet {
	width: 10px;
	height: 10px;
}
.mco-teaser-list-01 .swiper-button-next,
.mco-teaser-list-01 .swiper-button-prev {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	background: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	top: auto;
	bottom: 0;
	right: auto !important;
	margin-left: 6px;
	transform: none;
	left: 50% !important;
}
.mco-teaser-list-01 .swiper-button-prev {
	margin-left: -42px;
}
.mco-teaser-list-01 .swiper-button-disabled {
	pointer-events: none;
	opacity: 0.3 !important;
}
.mco-teaser-list-01 .swiper-button-lock {
	display: none !important;
}
.mco-teaser-list-01 .swiper-button-next svg,
.mco-teaser-list-01 .swiper-button-prev svg {
	width: 12px !important;
}
.mco-teaser-01 {
	background: #ffffff !important;
	border-radius: 8px;
}
.mco-teaser-01 a {
	height: 100%;
}
.mco-teaser-01 a picture {
	height: auto !important;
	width: auto !important;
}
.mco-teaser-01 a picture img {
	border-top-left-radius: 8px;
	border-top-right-radius: 8px;
}
.mco-teaser-01 .mco-teaser-01__title {
	color: #1b2832;
	font-size: 24px;
	line-height: 26px;
	text-align: left;
}
.mco-teaser-01 .mco-teaser-01__button button {
	color: var(--mco-palette-secondary-main) !important;
	height: auto;
	padding: 0;
}
.mco-teaser-01 .mco-teaser-01__button button:hover {
	background: none;
	color: var(--mco-palette-primary-main) !important;
}
.mco-teaser-01 .mco-teaser-01__button button svg {
	width: 10px !important;
}
.mco-teaser-01 .mco-teaser-01__button button svg path {
	fill: var(--mco-palette-secondary-main) !important;
	transition: fill 250ms ease;
}
.mco-teaser-01 .mco-teaser-01__button button:hover svg path {
	fill: var(--mco-palette-primary-main) !important;
}
.mco-teaser-01 .mco-teaser-01__date span {
	color: #a2ada0;
	font-size: 14px;
	line-height: 16px;
}

@media all and (min-width: 1200px) {
	.container-teasergroup-slider > div {
		font-size: 28px;
		line-height: 30px;
	}
}

/* Animation Teaser */

.animation-teaser-list .mco-teaser-list-01 .swiper-container {
	padding-bottom: 0 !important;
}
