.mco-gallery-01__list {
	margin: -12px !important;
}
.mco-gallery-01__item {
	overflow: hidden;
	position: relative;
}
.mco-gallery-01__item:before {
	border: 1px solid #ffffff;
	border-radius: 50%;
	background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjZmZmZmZmIg0KCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDUwIDUwIiB3aWR0aD0iNTBweCIgaGVpZ2h0PSI1MHB4Ij4NCgk8cGF0aCBkPSJNIDI0IDkgTCAyNCAyNCBMIDkgMjQgTCA5IDI2IEwgMjQgMjYgTCAyNCA0MSBMIDI2IDQxIEwgMjYgMjYgTCA0MSAyNiBMIDQxIDI0IEwgMjYgMjQgTCAyNiA5IFoiLz4NCjwvc3ZnPg==");
	content: "";
	height: 50px;
	left: 50%;
	top: 50%;
	position: absolute;
	background-size: 30px;
	background-repeat: no-repeat;
	transform: translate(-50%, -50%);
	width: 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	opacity: 0;
	background-position: center;
	transition: opacity 300ms ease;
	z-index: 1;
}
.mco-gallery-01__item:hover:before {
	opacity: 1;
}
.mco-gallery-01__item-image {
	display: block;
	transition: filter 300ms ease;
}
.mco-gallery-01__item:hover .mco-gallery-01__item-image {
	filter: blur(2px);
}
