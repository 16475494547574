.mco-singleteaser-01__content {
	position: relative;
}
.mco-singleteaser-01 .mco-singleteaser-01__title {
	font-size: 28px;
	margin-bottom: 16px;
	margin-top: 0;
	text-align: left;
}
.mco-singleteaser-01__content .mco-singleteaser-01__button button {
	background: #f0856c;
	font-size: 18px;
	color: #ffffff;
}
.mco-singleteaser-01__content .mco-singleteaser-01__button button:hover {
	background: #66736c;
}
