.mco-imagetext-01 .mco-imagetext-01__image {
	position: relative;
}
.mco-imagetext-01 .mco-imagetext-01__title {
	font-size: 28px;
	margin-bottom: 16px;
	text-align: left;
}
.mco-imagetext-01 .mco-imagetext-01__subtitle {
	font-family: "Rum-Sans";
	font-size: 16px;
	margin-top: 8px;
	text-align: left;
}
.mco-imagetext-01.mco-imagetext-01__wrapper--left .mco-imagetext-01__subtitle {
	text-align: right;
}

@media all and (max-width: 600px) {
	.mco-imagetext-01 .mco-imagetext-01__image {
		margin-bottom: 16px;
	}
	.mco-imagetext-01 .mco-imagetext-01__subtitle {
		background: rgba(0, 0, 0, 0.3);
		bottom: 16px;
		color: #ffffff !important;
		left: 16px;
		margin: 0;
		padding: 4px 8px;
		position: absolute;
	}
}
@media all and (min-width: 600px) {
	.mco-imagetext-01 .mco-imagetext-01__image img {
		object-fit: contain !important;
	}
}
