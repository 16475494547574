.mco-form-button-wrapper {
	display: inline-flex;
	justify-content: flex-end;
}
.mco-form-button-wrapper button {
	color: #ffffff;
}
.mco-form-spam-micado {
	background: none !important;
}
/* .container-form {
	background: #ffffff;
	padding-bottom: 80px;
	padding-top: 80px;
} */
