.mco-teaser-list-01--slider .mco-teaser-list-01__headline {
	margin-bottom: 30px;
	width: 100%;
}
.mco-teaser-list-01--slider .mco-teaser-list-01__headline h2 {
	display: block;
	font-size: 20px;
	padding-bottom: 8px;
	position: relative;
	text-align: left;
}
.mco-teaser-list-01--slider .mco-teaser-list-01__headline h2:before {
	background-image: linear-gradient(to right, rgba(50, 51, 48, 1) 30%, rgba(50, 51, 48, 0) 0%);
	background-position: bottom;
	background-size: 8px 2px;
	background-repeat: repeat-x;
	content: "";
	height: 2px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	max-width: 380px;
}
.mco-teaser-list-01--slider .mco-teaser-list-01__button a {
	height: auto;
}

/* Swiper */

.mco-teaser-list-01--slider .swiper-pagination-bullet {
	background: #ffffff;
	opacity: 1;
}
.mco-teaser-list-01--slider .swiper-pagination-bullet-active {
	background: #b55948;
}

@media all and (min-width: 960px) {
	.mco-teaser-list-01--slider .mco-teaser-list-01__headline {
		margin-bottom: 0;
		max-width: 50%;
	}
	.mco-teaser-list-01--slider .mco-teaser-list-01__headline h2::before {
		max-width: 380px;
	}
	.mco-teaser-list-01--slider > .mco-teaser-list-01__top {
		margin-bottom: 32px;
	}
}
