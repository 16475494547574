.header {
	height: 64px !important;
}

@media all and (min-width: 1280px) {
	.header {
		height: 96px !important;
	}
	.header.menu-visible {
		z-index: 1201;
	}
}
