@import "./header.css";
@import "./menu.css";
@import "./form.css";
@import "./list.css";
@import "./gallery.css";
@import "./imageText.css";
@import "./references.css";
@import "./singleimage.css";
@import "./singleteaser.css";
@import "./table.css";
@import "./teaser.css";
@import "./teaser-list.css";
@import "./video.css";
@import "./facts.css";
@import "./ial.css";

html {
	font-size: 100%;
	min-height: calc(100% + env(safe-area-inset-top));
}
body {
	background: #d0d2d0 !important;
	margin: 0;
}
body.freiraum,
body.grenzenlos {
	background: #ffffff !important;
}
body.inno,
body.egen {
	background: rgb(255 240 230) !important;
}
main {
	min-height: max(550px, calc(var(--vh, 1vh) * 60));
	position: relative;
}
body.noanimation main {
	padding-top: 60px;
}
a {
	color: var(--palette-primary-main);
	text-decoration: none;
}
p {
	margin: 0;
}
ul {
	list-style: inside;
	margin: 0;
	padding: 0;
}
/* *:not(.no-custom-scrollbar) {
	scrollbar-color: var(--palette-primary-main) transparent;
	scrollbar-width: thin;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar {
	width: 10px;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-track {
	background: transparent;
}
*:not(.no-custom-scrollbar)::-webkit-scrollbar-thumb {
	background-color: var(--palette-primary-main);
	border: 0 solid transparent;
	border-radius: 0;
} */
.container-margin {
	margin-bottom: 80px;
	margin-top: 80px;
}
.container-headline + .container-headline {
	margin-top: calc(80px * -1 + 24px);
}
.container-headline-2 + .container-headline-3 h3 {
	text-align: center;
}
.container-headline-1 + .container-text {
	margin-top: calc(80px * -1 + 32px);
}
.container-employee + .container-employee {
	margin-top: -40px;
}
.container-teasergroup-custom {
	background: #ffffff;
	padding: 80px 0;
}
.container-teasergroup-slider > div {
	padding-left: 0;
	padding-right: 0;
}
.container-dropdown .MuiPaper-root {
	border-bottom: 2px solid #cccccc;
	border-top: 2px solid #cccccc;
}
.container-dropdown .MuiAccordionSummary-content > h2 {
	color: #333333;
}
.container-dropdown .MuiIconButton-label > svg > path {
	fill: #333333;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
	transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
	transition-delay: background-color 5000s, color 5000s;
}
body.animation-teaser .mco-animation-01:before {
	background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%);
	background-blend-mode: multiply;
	content: "";
	height: 100%;
	left: 0;
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 2;
}

@media all and (min-width: 960px) {
	body.noanimation main {
		padding-top: 96px;
	}
}

/* Freiraum Styles - override */

body.freiraum .teaser-list .teaser-item__title {
	color: #97bf0d !important;
}
body.freiraum .mco-singleteaser-01 .mco-singleteaser-01__button button {
	background: #97bf0d !important;
}
body.freiraum .container-references {
	background: #2e2b2b !important;
}
body.freiraum .mco-download-01 svg path:first-child {
	fill: #97bf0d !important;
}
body.freiraum .mco-form-button {
	background: #97bf0d !important;
}
body.freiraum .mco-form .MuiOutlinedInput-root.Mui-focused fieldset {
	border-color: #97bf0d !important;
}
body.freiraum .mco-form .MuiFormLabel-root.Mui-focused {
	color: #97bf0d !important;
}
body.freiraum .imagetext-button a {
	background: #97bf0d !important;
}

/* Grenzenlos Styles - override */

body.grenzenlos .teaser-list .teaser-item__title {
	color: #96c11f !important;
}
body.grenzenlos .mco-singleteaser-01 .mco-singleteaser-01__button button {
	background: #96c11f !important;
}
body.grenzenlos .container-references {
	background: #3c3c3b !important;
}
body.grenzenlos .mco-download-01 svg path:first-child {
	fill: #96c11f !important;
}
body.grenzenlos .mco-form-button {
	background: #96c11f !important;
}
body.grenzenlos .mco-form .MuiOutlinedInput-root.Mui-focused fieldset {
	border-color: #96c11f !important;
}
body.grenzenlos .mco-form .MuiFormLabel-root.Mui-focused {
	color: #96c11f !important;
}
body.grenzenlos .imagetext-button a {
	background: #96c11f !important;
}

/* Innovatinosraum Kufstein && eGen */

body.inno .mco-view-component-anchormenu,
body.egen .mco-view-component-anchormenu {
	background: rgb(255, 240, 230) !important;
}
body.inno .container-references,
body.egen .container-references {
	background: #645a5a !important;
}
