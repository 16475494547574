.menu-01-hamburger {
	color: #ffffff;
}
.menu-01-hamburger svg {
	fill: #ffffff;
}
.menu-01-hamburger svg * {
	fill: #ffffff;
}
.menu-01-hamburger__label {
	font-family: "Rum-Serif" !important;
	margin-right: 12px !important;
}
.menu-01-mobile {
	inset: 0;
	overflow-x: hidden;
	overflow-y: auto;
	position: fixed;
	width: 100%;
	z-index: 1100 !important;
}
.menu-01-mobile-container {
	display: flex;
	overflow-x: unset;
	overflow-y: unset;
	flex-direction: column;
	position: relative;
	z-index: 100;
}
.menu-01-mobile-backdrop {
	background: #355a55 !important;
	z-index: 99;
}
body.freiraum .menu-01-mobile-backdrop {
	background: #97bf0d !important;
}
body.grenzenlos .menu-01-mobile-backdrop {
	background: #96c11f !important;
}
.menu-01-mobile-close {
	margin-bottom: 24px;
	padding-left: 20px !important;
}
.menu-01-mobile-close > div {
	background: #ffffff;
	width: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
}
.menu-01-mobile-close svg {
	width: 32px !important;
}
.menu-01-mobile-close svg * {
	fill: #355a55 !important;
}
body.freiraum .menu-01-mobile-close svg * {
	fill: #97bf0d !important;
}
body.grenzenlos .menu-01-mobile-close svg * {
	fill: #96c11f !important;
}
.menu-01-mobile-menuitem__level1 svg {
	height: 12px !important;
}
.menu-01-mobile-menuitem__level2 svg {
	margin-left: 8px;
	width: 12px !important;
}
.menu-01-mobile-menuitem__link {
	color: #ffffff !important;
}
.menu-01-mobile-menuitem__link--selected {
	color: #ffffff !important;
	text-decoration: underline;
	text-underline-offset: 5px;
}
.menu-01-mobile-menuitem__level2 .menu-01-mobile-menuitem__link {
	color: #ffffff !important;
}
.menu-01-mobile-menuitem__title {
	color: #ffffff !important;
	font-size: 22px;
	font-family: "Rum-Serif";
	font-weight: 600;
}
.menu-01-mobile-menuitem__level3 .menu-01-mobile-menuitem__title {
	font-size: 16px;
	font-weight: 300;
	font-family: "Rum-Sans";
}
.menu-01-mobile-subcontent__title {
	color: #1d413c !important;
}
.menu-01-mobile-subcontainer {
	background: #70918d !important;
}
.menu-01-mobile-subcontainer > div .menu-01-mobile-subcontainer {
	background: #ffffff !important;
	padding: 8px 24px !important;
}
.menu-01-mobile-menuitem__level3 a,
.menu-01-mobile-menuitem__level3 a span {
	color: #333333 !important;
}
.menu-01-mobile-subcontainer__back {
	color: #ffffff !important;
	margin-bottom: 40px !important;
}
.menu-01-mobile-subcontainer__back svg {
	margin-right: 8px;
	height: 12px !important;
	width: auto !important;
}
.menu-01-mobile-metamenu {
	display: table !important;
}
.menu-01-mobile-metamenuitem__link {
	color: #ffffff !important;
	font-size: 14px;
	font-family: "Rum-Serif" !important;
	padding: 4px 0 !important;
}
.menu-01-desktop-menuitem__link {
	color: #ffffff !important;
	font-size: 18px !important;
	line-height: 24px !important;
}
.menu-01-desktop-menuitem__level1 .menu-01-desktop-menuitem__title,
.menu-01-desktop-menuitem__level2 .menu-01-desktop-menuitem__title {
	font-family: "Rum-Serif";
	font-weight: 600 !important;
}
.menu-01-desktop-menuitem__link:hover,
.menu-01-desktop-menuitem__link--selected {
	color: #ffffff !important;
	text-decoration: underline !important;
	text-underline-offset: 5px !important;
}
.menu-01-desktop-overlay-parent .menu-01-desktop-menuitem__link {
	color: #333333 !important;
}
.menu-01-desktop-overlay-parent
	.menu-01-desktop-menuitem__level2
	.menu-01-desktop-menuitem__link--selected {
	text-decoration: underline;
}
.menu-01-desktop-overlay-parent .menu-01-desktop-menuitem__link:hover {
	color: #f0856c !important;
}
body.freiraum .menu-01-desktop-overlay-parent .menu-01-desktop-menuitem__link:hover {
	color: #97bf0d !important;
}
body.grenzenlos .menu-01-desktop-overlay-parent .menu-01-desktop-menuitem__link:hover {
	color: #96c11f !important;
}
.menu-01-desktop-overlay {
	padding: 24px 56px !important;
}
.menu-01-desktop-container.menu-01-desktop-container__vertical {
	max-width: 1240px;
	margin: 0 auto;
	padding-left: 330px;
}
body.intro-nologo .menu-01-desktop-container.menu-01-desktop-container__vertical {
	padding-left: 0 !important;
}

@media all and (min-width: 1280px) {
	.menu-01-desktop {
		align-items: center;
		display: flex;
	}
	.menu-01-desktop-overlay {
		background: #e6e6e3 !important;
		padding-top: 56px !important;
		top: 90px !important;
	}
}
