main > .container-references:last-child {
	margin-bottom: 0;
}
.container-references {
	background: #355a55;
}
.mco-references-01 {
	padding-bottom: 80px;
	padding-top: 80px;
}
.mco-references-01 h2 {
	color: #ffffff !important;
	display: table;
	font-size: 28px;
	margin: 0 auto 32px;
	padding-bottom: 16px;
	position: relative;
	text-align: center;
}
.mco-references-01 h2:before {
	background-image: linear-gradient(
		to right,
		rgba(255, 255, 255, 1) 30%,
		rgba(255, 255, 255, 0) 0%
	);
	background-position: bottom;
	background-size: 8px 2px;
	background-repeat: repeat-x;
	content: "";
	height: 2px;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0 auto;
	max-width: 250px;
	right: 0;
}
.mco-references-01__list {
	flex-direction: column;
}
.mco-references-01__list-item {
	flex-basis: auto !important;
	margin: 12px auto !important;
	padding: 0 !important;
}
.mco-references-01__list-item a {
	align-items: center;
	border: 2px solid #ffffff;
	height: 56px;
	justify-content: center;
	min-width: 200px;
	padding: 0 20px;
	transition: background-color 300ms ease;
}
.mco-references-01__list-item a:hover {
	background: #ffffff;
}
.mco-references-01__list-item a div {
	color: #ffffff;
	font-family: "Rum-Serif";
	font-weight: 600;
	transition: color 300ms ease;
}
.mco-references-01__list-item a:hover div {
	color: #355a55;
}

@media all and (min-width: 600px) {
	.mco-references-01__list {
		flex-direction: row;
		justify-content: center;
	}
	.mco-references-01__list-item {
		margin: 12px !important;
	}
}
